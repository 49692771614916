import axios from 'axios'
import jenks from 'turf-jenks'
const utmObj = require('utm-latlng')
const turf = require('@turf/turf')
const randomPointsOnPolygon = require('random-points-on-polygon')

export default {
  mapGrid ({ commit, state }) {
    if (state.mapObject.getLayer('grid')) {
      state.mapObject.removeLayer('gridOutline')
      state.mapObject.removeSource('gridOutline')
      state.mapObject.removeLayer('grid')
      state.mapObject.removeSource('grid')
    }
    state.mapObject.addLayer({
      id: 'gridOutline',
      type: 'circle',
      source: {
        type: 'geojson',
        data: state.gridGeoJson
      },
      layout: {},
      paint: {
        'circle-radius': 8,
        'circle-color': 'white'
      }
    })
    state.mapObject.addLayer({
      id: 'grid',
      type: 'circle',
      source: {
        type: 'geojson',
        data: state.gridGeoJson
      },
      layout: {},
      paint: {
        'circle-radius': 6,
        'circle-color': '#ffc107'
      }
    })
    state.mapObject.setPaintProperty('blocksLayer', 'fill-opacity', 0)
    state.mapObject.setPaintProperty('blocksLayerOutline', 'line-opacity', 0)
    state.selectedBlocks.forEach((block, i) => {
      if (state.mapObject.getLayer(block.properties.blockId)) {
        state.mapObject.removeLayer(block.properties.blockId)
        state.mapObject.removeSource(block.properties.blockId)
      }
    })
    // const gridBounds = turf.bbox(state.gridGeoJson)
    // state.mapObject.fitBounds(gridBounds, {
    //   padding: 50,
    //   animate: true
    // })
  },
  post ({ commit, state }) {
    commit('setStateProperty', { property: 'posting', value: true })
    const postName = state.post.name
    const postGeoJson = JSON.stringify(state.gridGeoJson)
    const postObject = {
      name: postName,
      createdBy: 'grid_generator',
      farmId: state.farm.id,
      geoJson: postGeoJson,
      folderId: state.folder.id
    }
    axios.post('https://api.efficientvineyard.com/post/geojson', postObject).then(response => {
      if (response.data.success) {
        commit('setStateProperty', { property: 'posting', value: false })
        commit('setAlert', { active: true, message: 'This new dataset has been added to your myEV farm!', level: 'alert-success', timeout: 4000 })
      } else {
        commit('setAlert', { active: true, message: 'There was an issue posting your grid to myEV. The dataset may have been too large. Consider downloading the grid directly and manually uploading to myEV.', level: 'alert-danger', timeout: 8000 })
      }
    }).catch(err => {
      console.log(err)
      commit('setAlert', { active: true, message: 'There was an issue posting your grid to myEV. The dataset may have been too large. Consider downloading the grid directly and manually uploading to myEV.', level: 'alert-danger', timeout: 12000 })
    })
  },
  download ({ commit, state }) {
    let fileContents = JSON.stringify(state.gridGeoJson)
    fileContents = [fileContents]
    const blob = new Blob(fileContents, { type: 'text/plain;charset=utf-8' })
    const url = window.URL || window.webkitURL
    const link = url.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = state.post.name + '.geojson'
    a.href = link
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  },
  reset ({ commit, state }) {
    state.mapObject.setPaintProperty('blocksLayer', 'fill-opacity', 0.8)
    state.mapObject.setPaintProperty('blocksLayerOutline', 'line-opacity', 1)
    if (state.mapObject.getLayer('datasetPreview')) {
      state.mapObject.removeLayer('datasetPreview')
      state.mapObject.removeSource('datasetPreview')
    }
    if (state.mapObject.getLayer('grid')) {
      state.mapObject.removeLayer('gridOutline')
      state.mapObject.removeSource('gridOutline')
      state.mapObject.removeLayer('grid')
      state.mapObject.removeSource('grid')
    }
    state.selectedBlocks.forEach((block, i) => {
      if (state.mapObject.getLayer(block.properties.blockId)) {
        state.mapObject.removeLayer(block.properties.blockId)
        state.mapObject.removeSource(block.properties.blockId)
      }
    })
    commit('setStateProperty', { property: 'dataset', value: {} })
    commit('setStateProperty', { property: 'selectedBlocks', value: [] })
    commit('setStateProperty', { property: 'gridGeoJson', value: {} })
    const blocksBounds = turf.bbox(state.blocksGeoJson)
    state.mapObject.fitBounds(blocksBounds, {
      padding: 50,
      animate: true
    })
  },
  generateDatasetGrid ({ commit, state, dispatch }) {
    // Returns distance in meters (negative values for points inside) from a point to the edges of a polygon
    /* eslint-disable */
    function distanceToPolygon({ point, polygon }) {
      if (point && polygon) {
        if (polygon.type === "Feature") { polygon = polygon.geometry }
        let distance;
        if (polygon.type === "MultiPolygon") {
          distance = polygon.coordinates
            .map(coords => distanceToPolygon({ point, polygon: turf.polygon(coords).geometry }))
            .reduce((smallest, current) => (current < smallest ? current : smallest));
        } else {
          if (polygon.coordinates.length > 1) {
            // Has holes
            const [exteriorDistance, ...interiorDistances] = polygon.coordinates.map(coords =>
              distanceToPolygon({ point, polygon: turf.polygon([coords]).geometry })
            );
            if (exteriorDistance < 0) {
              // point is inside the exterior polygon shape
              const smallestInteriorDistance = interiorDistances.reduce(
                (smallest, current) => (current < smallest ? current : smallest)
              );
              if (smallestInteriorDistance < 0) {
                // point is inside one of the holes (therefore not actually inside this shape)
                distance = smallestInteriorDistance * -1;
              } else {
                // find which is closer, the distance to the hole or the distance to the edge of the exterior, and set that as the inner distance.
                distance = smallestInteriorDistance < exteriorDistance * -1
                  ? smallestInteriorDistance * -1
                  : exteriorDistance;
              }
            } else {
              distance = exteriorDistance;
            }
          } else {
            // The actual distance operation - on a normal, hole-less polygon (converted to meters)
            distance = turf.pointToLineDistance(point, turf.polygonToLineString(polygon)) * 1000;
            if (turf.booleanPointInPolygon(point, polygon)) {
              distance = distance * -1;
            }
          }
        }
        return distance
      }
    }
    /* eslint-enable */
    if (state.selectedBlocks.length < 1 && state.dataset.name && state.datasetHeader.length < 1) {
      commit('setAlert', { active: true, message: 'You must select blocks first and a dataset!', level: 'alert-warning', timeout: 4000 })
      return false
    }
    const withinBlocks = {
      type: 'FeatureCollection',
      features: []
    }
    const grid = {
      type: 'FeatureCollection',
      features: []
    }
    const selectedBlocksGeoJson = {
      type: 'FeatureCollection',
      features: state.selectedBlocks
    }
    const multiPolygon = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'MultiPolygon',
        coordinates: []
      }
    }
    selectedBlocksGeoJson.features.forEach(feature => {
      multiPolygon.geometry.coordinates.push(feature.geometry.coordinates)
    })
    // Filter to only within blocks and within filters
    state.datasetGeoJson.features.forEach(item => {
      const withinBlock = turf.booleanPointInPolygon(item, multiPolygon)
      if (withinBlock) {
        let withinFilters = true
        // Check filters too
        state.dataset.vizSettings.filters.forEach((filter, i) => {
          if (item.properties[filter.header] < filter.min || item.properties[filter.header] > filter.max) {
            withinFilters = false
          }
        })
        if (withinFilters) {
          withinBlocks.features.push(item)
        }
      }
    })
    // FILTER OUT EDGES OF BLOCKS
    if (state.avoidEdges) {
      for (let i = withinBlocks.features.length - 1; i >= 0; i--) {
        const point = withinBlocks.features[i]
        const polygon = multiPolygon
        const distance = distanceToPolygon({ point, polygon })
        if (distance >= -4.5) {
          withinBlocks.features.splice(i, 1)
        }
      }
    }

    // Sort by header
    withinBlocks.features.sort((a, b) => (a.properties[state.datasetHeader] > b.properties[state.datasetHeader]) ? 1 : -1)
    // Calculate the ideal point values
    const minValue = withinBlocks.features[0].properties[state.datasetHeader]
    const maxValue = withinBlocks.features[withinBlocks.features.length - 1].properties[state.datasetHeader]
    // If non-Stratified
    if (state.datasetModel === 'nonStratified') {
      if (state.distributionModel === 'random') {
        // Get Randon values within the withinBlocks geoJson
        // Shuffle features and get number of points from beginning
        grid.features = withinBlocks.features.sort(() => 0.5 - Math.random()).slice(0, state.gridCount)
      } else if (state.distributionModel === 'even') {
        // Non-Stratified Even
        const interval = (maxValue - minValue) / (state.gridCount - 1)
        const idealValues = []
        for (let i = 0; i < state.gridCount; i++) {
          const value = minValue + (interval * i)
          idealValues.push(value)
        }
        // Get closest points to those values and push to grid
        idealValues.forEach(value => {
          let finalValue = false
          for (let i = 0; i < withinBlocks.features.length; i++) {
            if (withinBlocks.features[i].properties[state.datasetHeader] > value && !finalValue) {
              const frontDiff = value - withinBlocks.features[i - 1].properties[state.datasetHeader]
              const backDiff = withinBlocks.features[i].properties[state.datasetHeader] - value
              if (frontDiff < backDiff) {
                finalValue = withinBlocks.features[i - 1]
              } else {
                finalValue = withinBlocks.features[i]
              }
            }
          }
          if (finalValue) {
            grid.features.push(finalValue)
          }
        })
        grid.features.push(withinBlocks.features[withinBlocks.features.length - 1])
      }
    } else if (state.datasetModel === 'stratified') {
      if (state.distributionModel === 'random') {
        if (state.dataset.vizSettings.zoneClassification === 'equal-interval') {
          const stopInterval = (maxValue - minValue) / state.dataset.vizSettings.zoneStops
          for (let i = 1; i <= state.dataset.vizSettings.zoneStops; i++) {
            const start = minValue + (stopInterval * (i - 1))
            const end = minValue + (stopInterval * i)
            const zoneGeoJson = {
              type: 'FeatureCollection',
              features: []
            }
            withinBlocks.features.forEach(feature => {
              if (feature.properties[state.datasetHeader] >= start && feature.properties[state.datasetHeader] <= end) {
                zoneGeoJson.features.push(feature)
              }
            })
            // FILTER OUT EDGES OF ZONES
            if (state.avoidEdges) {
              const remainingFeatures = []
              const zonePolygon = turf.concave(zoneGeoJson, { units: 'kilometers', maxEdge: 0.004 })
              zoneGeoJson.features.forEach(zoneGeoJsonFeature => {
                const point = zoneGeoJsonFeature
                const polygon = zonePolygon
                const dist = distanceToPolygon({ point, polygon })
                if (dist <= -4.5) {
                  remainingFeatures.push(zoneGeoJsonFeature)
                }
              })
              if (remainingFeatures.length > state.pointsPerZone) {
                zoneGeoJson.features = remainingFeatures
              }
            }
            // Get Randon values within the zoneGeoJson
            // Shuffle features and get number of points from beginning
            const randomZoneFeatures = zoneGeoJson.features.sort(() => 0.5 - Math.random()).slice(0, state.pointsPerZone)
            randomZoneFeatures.forEach((randomZoneFeature, i) => {
              grid.features.push(randomZoneFeature)
            })
          }
        } else if (state.dataset.vizSettings.zoneClassification === 'jenks') {
          // Get breaks
          const jenksStops = jenks(withinBlocks, state.datasetHeader, state.dataset.vizSettings.zoneStops)
          // const stopInterval = (maxValue - minValue) / state.dataset.vizSettings.zoneStops
          for (let i = 1; i <= state.dataset.vizSettings.zoneStops; i++) {
            const start = jenksStops[i - 1]
            const end = jenksStops[i]
            const zoneGeoJson = {
              type: 'FeatureCollection',
              features: []
            }
            withinBlocks.features.forEach(feature => {
              if (feature.properties[state.datasetHeader] >= start && feature.properties[state.datasetHeader] < end) {
                zoneGeoJson.features.push(feature)
              }
            })

            // FILTER OUT EDGES OF ZONES
            if (state.avoidEdges) {
              const remainingFeatures = []
              const zonePolygon = turf.concave(zoneGeoJson, { units: 'kilometers', maxEdge: 0.004 })
              zoneGeoJson.features.forEach(zoneGeoJsonFeature => {
                const point = zoneGeoJsonFeature
                const polygon = zonePolygon
                const dist = distanceToPolygon({ point, polygon })
                if (dist <= -4.5) {
                  remainingFeatures.push(zoneGeoJsonFeature)
                }
              })
              if (remainingFeatures.length > state.pointsPerZone) {
                zoneGeoJson.features = remainingFeatures
              }
            }
            // Get Randon values within the zoneGeoJson
            // Shuffle features and get number of points from beginning
            const randomZoneFeatures = zoneGeoJson.features.sort(() => 0.5 - Math.random()).slice(0, state.pointsPerZone)
            randomZoneFeatures.forEach((randomZoneFeature, i) => {
              grid.features.push(randomZoneFeature)
            })
          }
        }
      } else if (state.distributionModel === 'even') {
        if (state.dataset.vizSettings.zoneClassification === 'equal-interval') {
          const stopInterval = (maxValue - minValue) / state.dataset.vizSettings.zoneStops
          for (let i = 1; i <= state.dataset.vizSettings.zoneStops; i++) {
            const start = minValue + (stopInterval * (i - 1))
            const end = minValue + (stopInterval * i)
            const zoneGeoJson = {
              type: 'FeatureCollection',
              features: []
            }
            withinBlocks.features.forEach(feature => {
              if (feature.properties[state.datasetHeader] >= start && feature.properties[state.datasetHeader] <= end) {
                zoneGeoJson.features.push(feature)
              }
            })
            // FILTER OUT EDGES OF ZONES
            if (state.avoidEdges) {
              const remainingFeatures = []
              const zonePolygon = turf.concave(zoneGeoJson, { units: 'kilometers', maxEdge: 0.004 })
              zoneGeoJson.features.forEach(zoneGeoJsonFeature => {
                const point = zoneGeoJsonFeature
                const polygon = zonePolygon
                const dist = distanceToPolygon({ point, polygon })
                if (dist <= -4.5) {
                  remainingFeatures.push(zoneGeoJsonFeature)
                }
              })
              if (remainingFeatures.length > state.pointsPerZone) {
                zoneGeoJson.features = remainingFeatures
              }
            }
            // Calculate the ideal point values
            const interval = (end - start) / (state.pointsPerZone - 1)
            const idealValues = []
            for (let i = 0; i < state.pointsPerZone; i++) {
              const value = start + (interval * i)
              idealValues.push(value)
            }
            // Get closest points to those values and push to grid
            idealValues.forEach(value => {
              let finalValue = false
              for (let i = 1; i < zoneGeoJson.features.length; i++) {
                if (zoneGeoJson.features[i].properties[state.datasetHeader] > value && !finalValue) {
                  const frontDiff = value - zoneGeoJson.features[i - 1].properties[state.datasetHeader]
                  const backDiff = zoneGeoJson.features[i].properties[state.datasetHeader] - value
                  if (frontDiff < backDiff) {
                    finalValue = zoneGeoJson.features[i - 1]
                  } else {
                    finalValue = zoneGeoJson.features[i]
                  }
                }
              }
              if (finalValue) {
                grid.features.push(finalValue)
              }
            })
            grid.features.push(zoneGeoJson.features[zoneGeoJson.features.length - 1])
          }
        } else if (state.dataset.vizSettings.zoneClassification === 'jenks') {
          // Get breaks
          const jenksStops = jenks(withinBlocks, state.datasetHeader, state.dataset.vizSettings.zoneStops)
          // const stopInterval = (maxValue - minValue) / state.dataset.vizSettings.zoneStops
          for (let i = 1; i <= state.dataset.vizSettings.zoneStops; i++) {
            const start = jenksStops[i - 1]
            const end = jenksStops[i]
            const zoneGeoJson = {
              type: 'FeatureCollection',
              features: []
            }
            withinBlocks.features.forEach(feature => {
              if (feature.properties[state.datasetHeader] >= start && feature.properties[state.datasetHeader] < end) {
                zoneGeoJson.features.push(feature)
              }
            })
            // FILTER OUT EDGES OF ZONES
            if (state.avoidEdges) {
              const remainingFeatures = []
              const zonePolygon = turf.concave(zoneGeoJson, { units: 'kilometers', maxEdge: 0.004 })
              zoneGeoJson.features.forEach(zoneGeoJsonFeature => {
                const point = zoneGeoJsonFeature
                const polygon = zonePolygon
                const dist = distanceToPolygon({ point, polygon })
                if (dist <= -4.5) {
                  remainingFeatures.push(zoneGeoJsonFeature)
                }
              })
              if (remainingFeatures.length > state.pointsPerZone) {
                zoneGeoJson.features = remainingFeatures
              }
            }
            // Calculate the ideal point values
            const interval = (end - start) / (state.pointsPerZone - 1)
            const idealValues = []
            for (let i = 0; i < state.pointsPerZone; i++) {
              const value = start + (interval * i)
              idealValues.push(value)
            }
            // Get closest points to those values and push to grid
            idealValues.forEach(value => {
              let finalValue = false
              for (let i = 1; i < zoneGeoJson.features.length; i++) {
                if (zoneGeoJson.features[i].properties[state.datasetHeader] > value && !finalValue) {
                  const frontDiff = value - zoneGeoJson.features[i - 1].properties[state.datasetHeader]
                  const backDiff = zoneGeoJson.features[i].properties[state.datasetHeader] - value
                  if (frontDiff < backDiff) {
                    finalValue = zoneGeoJson.features[i - 1]
                  } else {
                    finalValue = zoneGeoJson.features[i]
                  }
                }
              }
              if (finalValue) {
                grid.features.push(finalValue)
              }
            })
            grid.features.push(zoneGeoJson.features[zoneGeoJson.features.length - 1])
          }
        }
      }
    }
    commit('setStateProperty', { property: 'gridGeoJson', value: grid })
    dispatch('mapGrid')
    // })
  },
  generateRandomGrid ({ commit, state, dispatch }) {
    if (state.selectedBlocks < 1) {
      commit('setAlert', { active: true, message: 'You must select blocks first!', level: 'alert-warning', timeout: 2000 })
      return false
    }
    const grid = {
      type: 'FeatureCollection',
      features: []
    }
    const selectedBlocksGeoJson = {
      type: 'FeatureCollection',
      features: state.selectedBlocks
    }
    const multiPolygon = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'MultiPolygon',
        coordinates: []
      }
    }
    selectedBlocksGeoJson.features.forEach(feature => {
      multiPolygon.geometry.coordinates.push(feature.geometry.coordinates)
    })
    grid.features = randomPointsOnPolygon(state.gridCount, multiPolygon)
    commit('setStateProperty', { property: 'gridGeoJson', value: grid })
    dispatch('mapGrid')
  },
  generateEvenGrid ({ commit, state, dispatch }) {
    console.log('Generate Even Grid')
    commit('setStateProperty', { property: 'loader', value: true })
    commit('setStateProperty', { property: 'loaderValue', value: 0 })
    commit('setStateProperty', { property: 'loaderMax', value: state.blocksGeoJson.features.length })
    if (state.selectedBlocks < 1) {
      commit('setAlert', { active: true, message: 'You must select blocks first!', level: 'alert-warning', timeout: 2000 })
      return false
    }
    // const grid = {
    //   type: 'FeatureCollection',
    //   features: []
    // }
    // Default Ellipsoid is 'WGS 84'
    const utm = new utmObj() // eslint-disable-line
    // Combine selected blocks
    const selectedBlocksGeoJson = {
      type: 'FeatureCollection',
      features: state.selectedBlocks
    }

    const combinedTrimmedGrid = {
      type: 'FeatureCollection',
      features: []
    }

    // START LOOP

    selectedBlocksGeoJson.features.forEach(selectedBlock => {
      console.log(selectedBlock)
      console.log(state.loaderValue)
      const loaderValue = state.loaderValue + 1
      commit('setStateProperty', { property: 'loaderValue', value: loaderValue })
      // Create bouding box around selected blocks geoJson
      const grid = {
        type: 'FeatureCollection',
        features: []
      }
      const bounds = turf.bbox(selectedBlock) // returns [ minX, minY, maxX, maxY ]
      const buffer = 0.001
      const minX = bounds[0] - buffer
      const minY = bounds[1] - buffer
      const maxX = bounds[2] + buffer
      const maxY = bounds[3] + buffer
      // Setup grid geoJson based on Universal Grid
      // Convert Bounds to UTM SW and NE
      // Output example { Easting: 464659.99600809, Northing: 1614646.6030166026, ZoneNumber: 37, ZoneLetter: "P" }
      const utmSW = utm.convertLatLngToUtm(minY, minX, 14) // 14 accuracy based on geojson.io outputs
      const utmNE = utm.convertLatLngToUtm(maxY, maxX, 14) // 14 accuracy based on geojson.io outputs
      const zoneNum = utmSW.ZoneNumber
      const zoneLetter = utmSW.ZoneLetter
      // Calculate distance to get to desired number
      let distance = state.gridDistance
      if (state.gridDistributedBy === 'count') {
        // Get blocks area
        const selectedBlocksArea = turf.area(selectedBlocksGeoJson)
        const areaPerPoint = selectedBlocksArea / state.gridCount
        distance = Math.sqrt(areaPerPoint)
        // For count based calculations, just leave the easting and northing alone
      } else if (state.gridDistributedBy === 'distance') {
        // For distance based calculations round SW up to nearest easting and northing that is a multiple of distance
        // This ensures universality of distance-based grids
        utmSW.Easting = Math.ceil(utmSW.Easting / distance) * distance
        utmSW.Northing = Math.ceil(utmSW.Northing / distance) * distance
      }
      // Round SW up to nearest easting and northing that is a multiple of distance
      // utmSW.Easting = Math.ceil(utmSW.Easting / distance) * distance
      // utmSW.Northing = Math.ceil(utmSW.Northing / distance) * distance
      // Iterate from there east until hitting the east boundary
      for (let northing = utmSW.Northing; northing < utmNE.Northing; northing = northing + distance) {
        for (let easting = utmSW.Easting; easting < utmNE.Easting; easting = easting + distance) {
          // Convert each coordinate back to Lat/Long
          const latLng = utm.convertUtmToLatLng(easting, northing, zoneNum, zoneLetter)
          // Convert
          grid.features.push({
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [
                latLng.lng,
                latLng.lat
              ]
            }
          })
        }
      }
      // Trim Grid Points to the polygon
      const trimmedGrid = turf.pointsWithinPolygon(grid, selectedBlocksGeoJson)
      // Push these trimmed grid points to the combinedTrimmedGrid
      trimmedGrid.features.forEach(trimmedGridFeature => {
        combinedTrimmedGrid.features.push(trimmedGridFeature)
      })
    })

    // END LOOP

    commit('setStateProperty', { property: 'gridGeoJson', value: combinedTrimmedGrid })
    dispatch('mapGrid')
    commit('setStateProperty', { property: 'loader', value: false })
    commit('setStateProperty', { property: 'loaderValue', value: 0 })
  },
  alert ({ commit, state }, message) {
    setTimeout(() => {
      commit('setStateProperty', { property: 'alert', value: message })
    }, 100)
    setTimeout(() => {
      commit('setStateProperty', { property: 'alert', value: false })
    }, 3000)
  },
  initGridMap ({ commit, state, dispatch }) {
    mapboxgl.accessToken = 'pk.eyJ1IjoibGVyZ3AiLCJhIjoiY2p4bmI1NzNzMGN0MTNjcGx4cjF4eDBtdSJ9.2C0FEHhNZ-jGd7jgIRTrEQ' // eslint-disable-line
    state.mapObject = new mapboxgl.Map({ // eslint-disable-line
      container: 'gridMap',
      // style: 'mapbox://styles/mapbox/satellite-v9',
      style: 'https://api.maptiler.com/maps/da520b6d-739a-4646-888f-1d3f2b664f78/style.json?key=IuJ0D0R9S0Cjl4krhvr1',
      center: [-95, 40],
      zoom: 3
    })

    state.mapObject.addControl(new mapboxgl.NavigationControl()) // eslint-disable-line
    // WHEN MAP LOADS SETUP BLOCKS
    state.mapObject.on('load', function () {
      // VARS
      const bounds = new mapboxgl.LngLatBounds() // eslint-disable-line
      // Get blocks geoJson File
      const blocksGeoJsonUrl = state.farm.blocksGeoJsonURL
      axios.get(blocksGeoJsonUrl).then(response => {
        state.blocksGeoJson = response.data
        // ADD LAYERS
        state.mapObject.addLayer({
          id: 'blocksLayer',
          type: 'fill',
          source: {
            type: 'geojson',
            data: state.blocksGeoJson
          },
          layout: {},
          paint: {
            'fill-color': '#6e59c7', // eslint-disable-line
            'fill-opacity': 0.8, // eslint-disable-line
          }
        })
        state.mapObject.addLayer({
          id: 'blocksLayerOutline',
          type: 'line',
          source: {
            type: 'geojson',
            data: state.blocksGeoJson
          },
          layout: {},
          paint: {
            'line-width': 2,
            'line-color': '#eee',
            'line-opacity': 1
          }
        })
        //
        //
        // CLICK EVENTS
        state.mapObject.on('click', 'blocksLayer', function (e) {
          if (!state.dataset.id) {
            const currentBlocks = state.selectedBlocks
            // Remove highlighted block layers
            currentBlocks.forEach((block, i) => {
              if (state.mapObject.getLayer(block.properties.blockId)) {
                state.mapObject.removeLayer(block.properties.blockId)
                state.mapObject.removeSource(block.properties.blockId)
              }
            })
            // Select clicked block if not already selected
            let wasSelected = false
            for (let i = currentBlocks.length - 1; i >= 0; i--) {
              if (currentBlocks[i].properties.blockId === e.features[0].properties.blockId) {
                currentBlocks.splice(i, 1)
                wasSelected = true
              }
            }
            if (!wasSelected) {
              currentBlocks.push(e.features[0])
            }
            currentBlocks.forEach((block, i) => {
              state.mapObject.addLayer({
                id: block.properties.blockId,
                type: 'fill',
                source: {
                  type: 'geojson',
                  data: block
                },
                layout: {},
                paint: {
                  'fill-color': '#ffc107', // eslint-disable-line
                  'fill-opacity': 0.8, // eslint-disable-line
                }
              })
            })
            commit('setStateProperty', { property: 'selectedBlocks', value: currentBlocks })
          }
        })
        // MANUALLY ADDING POINTS
        state.mapObject.on('click', 'datasetPreview', function (e) {
          if (state.datasetModel === 'manual') {
            const clickedPoint = e.features[0]
            let removePoint = false
            const grid = {
              type: 'FeatureCollection',
              features: []
            }
            if (state.gridGeoJson.features) {
              for (let i = state.gridGeoJson.features.length - 1; i >= 0; i--) {
                if (clickedPoint.geometry.coordinates[0] === state.gridGeoJson.features[i].geometry.coordinates[0] && clickedPoint.geometry.coordinates[1] === state.gridGeoJson.features[i].geometry.coordinates[1]) {
                  console.log('Removed Point')
                  removePoint = true
                } else {
                  grid.features.push({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                      type: 'Point',
                      coordinates: state.gridGeoJson.features[i].geometry.coordinates
                    }
                  })
                }
              }
            }
            if (!removePoint) {
              grid.features.push({
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: clickedPoint.geometry.coordinates
                }
              })
              commit('setStateProperty', { property: 'gridGeoJson', value: grid })
              dispatch('mapGrid')
            }
          }
        })
        // REMOVE POINTS
        state.mapObject.on('click', 'gridOutline', function (e) {
          if (state.datasetModel === 'manual') {
            const clickedPoint = e.features[0]
            // let removePoint = false
            const grid = {
              type: 'FeatureCollection',
              features: []
            }
            if (state.gridGeoJson.features) {
              for (let i = state.gridGeoJson.features.length - 1; i >= 0; i--) {
                if (clickedPoint.geometry.coordinates[0] === state.gridGeoJson.features[i].geometry.coordinates[0] && clickedPoint.geometry.coordinates[1] === state.gridGeoJson.features[i].geometry.coordinates[1]) {
                  console.log('Removed Point')
                } else {
                  grid.features.push({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                      type: 'Point',
                      coordinates: state.gridGeoJson.features[i].geometry.coordinates
                    }
                  })
                }
              }
            }
            commit('setStateProperty', { property: 'gridGeoJson', value: grid })
            dispatch('mapGrid')
          }
        })
        // END CLICK EVENTS
        //
        //
        // MOUSE ENTER AND LEAVE POINTERS
        state.mapObject.on('mouseenter', 'blocksLayer', function () {
          state.mapObject.getCanvas().style.cursor = 'pointer'
        })
        state.mapObject.on('mouseleave', 'blocksLayer', function () {
          state.mapObject.getCanvas().style.cursor = ''
        })
        // BOUNDS UPDATE ONLY IF THERE ARE FEATURES
        if (state.blocksGeoJson.features.length > 0) {
          state.blocksGeoJson.features.forEach(feature => {
            feature.geometry.coordinates[0].forEach(coordinatePair => {
              bounds.extend(coordinatePair)
            })
          })
          state.mapObject.fitBounds(bounds, {
            padding: 50,
            animate: false
          })
        } else {
          const lat = state.farm.geolocation.latitude
          const lng = state.farm.geolocation.longitude
          state.mapObject.flyTo({
            center: [lng, lat],
            zoom: 15,
            animate: false
          })
        }
      }) // axios get blocksGeoJson
    }) // map.on load
  },
  initPlugin ({ commit, state, dispatch }, farmId) {
    commit('setStateProperty', { property: 'spinner', value: true })
    const timeStamp = new Date().getTime()
    const url = 'https://api.efficientvineyard.com/farm/' + farmId + '?timestamp=' + timeStamp
    axios.get(url).then(response => {
      response.data.id = farmId
      commit('setStateProperty', { property: 'farm', value: response.data })
      commit('setStateProperty', { property: 'spinner', value: false })
      dispatch('initGridMap')
      return true
    })
    // Get Root Folders
    // Get Root Datasets
  }
}
