<template>
  <div id="options">
    <div class="container pt-4">
      <p class="mb-1"><strong>Selected Blocks</strong> ({{ $store.state.selectedBlocks.length }})</p>
      <p v-if="$store.state.selectedBlocks.length < 1" class="text-danger"><small>Click blocks on the map to select.</small></p>
      <a
        class="btn btn-primary btn-sm"
        @click="selectAllBlocks()"
      >Select all Blocks</a>
      <ul id="blocksList">
        <li
          v-for="(block, i) in $store.state.selectedBlocks"
          v-bind:key="i"
        >{{ block.properties.name }}</li>
      </ul>
      <div v-if="$store.state.selectedBlocks.length > 0">
        <p class="mb-1"><strong>Grid Base</strong></p>
        <b-form-select
          class="mb-1"
          v-model="gridType"
          :options="gridTypes"
          @change="$store.dispatch('reset')"
        ></b-form-select>
        <!-- Distributed By Options -->
        <b-form-select class="mb-1" v-if="gridType === 'even'" v-model="gridDistributedBy" :options="gridDistributedByOptions"></b-form-select>
        <!-- Distance -->
        <b-form-select class="mb-1" v-if="gridType === 'even' && gridDistributedBy === 'distance'" v-model="gridDistance" :options="gridDistances"></b-form-select>
        <!-- Number Even Distribution -->
        <b-form-group class="mb-1" v-if="gridType === 'even' && gridDistributedBy === 'count'" description="This is an approximation. Actual grid count may vary slightly.">
          <b-form-input v-model="gridCount" type="number"></b-form-input>
        </b-form-group>
        <!-- Number Random Distribution -->
        <b-form-group class="mb-1" v-if="gridType === 'random'" description="Enter a number of points you'd like randomly generated.">
          <b-form-input v-model="gridCount" type="number"></b-form-input>
        </b-form-group>

        <!-- Dataset Picker -->
        <DatasetPicker v-if="gridType === 'dataset' && !$store.state.dataset.name" />
        <div v-if="gridType === 'dataset' && $store.state.dataset.name">
          <!-- SELECTED DATASET -->
          <div
            class="list-group-item list-group-item-action mb-2"
            style="background-color:lightgreen;"
          >
            <p class="lead mb-1">{{ $store.state.dataset.name }}</p>
            <i
              class="fas fa-times-circle clickable"
              id="remove-dataset"
              @click="removeDataset()"
            ></i>
            <!-- LOADING DATASET SPINNER -->
            <div
              v-if="$store.state.loadingDataset"
              class="loading-dataset-spinner"
            >
              <RingLoader
                :color="'#61539b'"
                id="spinner"
              ></RingLoader>
            </div>
          </div>
          <p class="mb-1"><strong>Header</strong></p>
          <b-form-group class="mb-1" description="Must select a header to calculate grid locations.">
            <b-form-select v-model="datasetHeader" :options="$store.state.dataset.headers"></b-form-select>
          </b-form-group>
          <p class="mb-1"><strong>Stratification</strong></p>
          <b-form-group class="mb-1" description="Non-stratified will disperse points evenly across block data. Statified will divide points between defined zones in your myEV account.">
            <b-form-select v-model="datasetModel" :options="datasetModelOptions"></b-form-select>
          </b-form-group>

          <div v-if="datasetModel !== 'manual'">
            <p class="mb-1"><strong>Point Distribution</strong></p>
            <b-form-group class="mb-1" description="Generate points randomly or evenly across values.">
              <b-form-select v-model="distributionModel" :options="distributionModelOptions"></b-form-select>
            </b-form-group>

            <!-- Avoid Edges -->
            <b-form-group class="mb-1 mt-2" description="The algorithm does its best to avoid generating points on the edges of blocks and zones.">
              <b-form-checkbox
                v-model="avoidEdges"
                name="check-button"
                switch
              >
                Avoid Edges
              </b-form-checkbox>
            </b-form-group>

            <div v-if="datasetModel === 'stratified'">
              <p v-if="$store.state.dataset.vizSettings.colorBy !== 'valueBased' || $store.state.dataset.vizSettings.zoneClassification === 'linear' || $store.state.dataset.vizSettings.zoneClassification === 'categorical'" class="text-danger">Must setup equal interval or jenks zones in myEV under 'Ramp Settings'!</p>
              <p v-if="$store.state.dataset.vizSettings.colorBy === 'valueBased' && $store.state.dataset.vizSettings.zoneClassification !== 'linear' && $store.state.dataset.vizSettings.zoneClassification !== 'categorical'">
                <strong>Settings from myEV</strong><br>
                Zones: {{ $store.state.dataset.vizSettings.zoneStops }}<br>
                Classification: {{ $store.state.dataset.vizSettings.zoneClassification }}
              </p>
              <p class="mb-1"><strong>Points per zone</strong></p>
              <!-- Points per zone -->
              <b-form-group class="mb-1">
                <b-form-input v-model="pointsPerZone" type="number"></b-form-input>
              </b-form-group>
            </div><!-- END Stratified stats -->
            <!-- Number of Stops -->
            <div v-if="datasetModel !== 'stratified'">
              <p class="mb-1"><strong>Number of Points</strong></p>
              <b-form-group class="mb-1">
                <b-form-input v-model="gridCount" type="number"></b-form-input>
              </b-form-group>
            </div>
            <b-btn
              v-if="$store.state.selectedBlocks.length > 0"
              variant="primary"
              @click="$store.dispatch('generateDatasetGrid')"
              class="mt-2"
            >Generate Grid</b-btn>
            <!--
            <b-btn
              v-if="gridType == 'even'"
              variant="primary"
              @click="$store.dispatch('generateEvenGrid')"
              class="mt-1"
            >Generate Grid</b-btn>
            <b-btn
              v-if="gridType == 'random'"
              variant="primary"
              @click="$store.dispatch('generateRandomGrid')"
              class="mt-1"
            >Generate Grid</b-btn>
            -->
          </div><!-- /not manual modal -->
          <p
            class="text-danger"
            v-if="datasetModel === 'manual'"
          >Please select points manually by clicking them on the map.</p>
        </div><!-- End Dataset settings -->

        <b-btn
          v-if="gridType == 'even'"
          variant="primary"
          @click="$store.dispatch('generateEvenGrid')"
          class="mt-1"
        >Generate Grid</b-btn>
        <b-btn
          v-if="gridType == 'random'"
          variant="primary"
          @click="$store.dispatch('generateRandomGrid')"
          class="mt-1"
        >Generate Grid</b-btn>

        <p v-if="$store.state.gridGeoJson.features" class="mb-1 mt-4"><strong>Use Grid</strong></p>
        <p v-if="$store.state.gridGeoJson.features">Point Count: {{ $store.state.gridGeoJson.features.length }}</p>

        <!-- CHOOSE LOCATION -->
        <b-form-group
          v-if="$store.state.gridGeoJson.features"
          label="New Dataset Name:"
        >
          <b-form-input v-model="post.name"></b-form-input>
        </b-form-group>

        <FolderPicker v-if="$store.state.gridGeoJson.features" />

        <b-btn
          v-if="$store.state.gridGeoJson.features"
          @click="$store.dispatch('download')"
          class="mr-1"
        >Download</b-btn>
        <b-btn
          v-if="$store.state.gridGeoJson.features && !$store.state.posting"
          @click="$store.dispatch('post')"
          variant="success"
        >Add to myEV</b-btn>
        <b-btn
          v-if="$store.state.posting"
          variant="light"
        ><i class="fas fa-sync fa-spin"></i> Adding now...</b-btn>
      </div><!-- /v-if blocks selected -->
      <b-progress :value="$store.state.loaderValuer" :max="$store.state.loaderMax" show-progress animated></b-progress>
    </div>
  </div><!-- /#options -->
</template>

<script>
import DatasetPicker from './DatasetPicker'
import FolderPicker from './FolderPicker'
import RingLoader from 'vue-spinner/src/GridLoader'

export default {
  data () {
    return {
      datasetModelOptions: [
        { value: 'nonStratified', text: 'Non-Stratified' },
        { value: 'stratified', text: 'Stratified' },
        { value: 'manual', text: 'Manual' }
      ],
      distributionModelOptions: [
        { value: 'random', text: 'Random' },
        { value: 'even', text: 'Even' }
      ],
      gridTypes: [
        { value: 'dataset', text: 'Using a Dataset' },
        { value: 'random', text: 'Random Points Across Blocks' },
        { value: 'even', text: 'Even Points Across Blocks' }
      ],
      gridDistributedByOptions: [
        { value: 'distance', text: 'By Distance' },
        { value: 'count', text: 'By Count' }
      ],
      gridDistances: [
        { value: 1, text: '1 Meter' },
        { value: 3, text: '3 Meters' },
        { value: 5, text: '5 Meters' },
        { value: 10, text: '10 Meters' },
        { value: 30, text: '30 Meters' },
        { value: 100, text: '100 Meters' }
      ]
    }
  },
  components: {
    DatasetPicker,
    RingLoader,
    FolderPicker
  },
  computed: {
    pointsPerZone: {
      get () {
        return this.$store.state.pointsPerZone
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'pointsPerZone', value: value })
      }
    },
    datasetHeader: {
      get () {
        return this.$store.state.datasetHeader
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'datasetHeader', value: value })
      }
    },
    datasetModel: {
      get () {
        return this.$store.state.datasetModel
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'datasetModel', value: value })
      }
    },
    distributionModel: {
      get () {
        return this.$store.state.distributionModel
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'distributionModel', value: value })
      }
    },
    post: {
      get () {
        return this.$store.state.post
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'post', value: value })
      }
    },
    gridType: {
      get () {
        return this.$store.state.gridType
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'gridType', value: value })
      }
    },
    gridDistance: {
      get () {
        return this.$store.state.gridDistance
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'gridDistance', value: value })
      }
    },
    gridCount: {
      get () {
        return this.$store.state.gridCount
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'gridCount', value: value })
      }
    },
    gridDistributedBy: {
      get () {
        return this.$store.state.gridDistributedBy
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'gridDistributedBy', value: value })
      }
    },
    avoidEdges: {
      get () {
        return this.$store.state.avoidEdges
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'avoidEdges', value: value })
      }
    }
  },
  methods: {
    selectAllBlocks () {
      const state = this.$store.state
      if (!state.dataset.id) {
        const currentBlocks = state.selectedBlocks
        // Remove highlighted block layers
        currentBlocks.forEach((block, i) => {
          if (state.mapObject.getLayer(block.properties.blockId)) {
            state.mapObject.removeLayer(block.properties.blockId)
            state.mapObject.removeSource(block.properties.blockId)
          }
        })
        // Select all blocks
        const allBlocks = state.blocksGeoJson.features
        allBlocks.forEach((block, i) => {
          state.mapObject.addLayer({
            id: block.properties.blockId,
            type: 'fill',
            source: {
              type: 'geojson',
              data: block
            },
            layout: {},
            paint: {
              'fill-color': '#ffc107', // eslint-disable-line
              'fill-opacity': 0.8, // eslint-disable-line
            }
          })
        })
        this.$store.commit('setStateProperty', { property: 'selectedBlocks', value: allBlocks })
      }
    },
    removeDataset () {
      this.$store.commit('setStateProperty', { property: 'dataset', value: {} })
      const mapObject = this.$store.state.mapObject
      if (mapObject.getLayer('datasetPreview')) {
        mapObject.removeLayer('datasetPreview')
        mapObject.removeSource('datasetPreview')
      }
    }
  }
}
</script>

<style scoped>
#options {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  z-index: 0;
  overflow-y: scroll;
  padding-bottom: 50px;
}

#blocksList {
  max-height: 300px;
  overflow-y: scroll;
  margin: 10px 5px;
  border: solid 1px rgba(0,0,0,0.3);
  border-radius: 4px;
}

#remove-dataset {
  position: absolute;
  top: 10px;
  right: 10px;
}

.loading-dataset-spinner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.3);
  width: 100%;
  height: 100%;
}
.v-spinner {
  position: absolute;
  left: calc(50% - 30px);
  top: 16px;
}

@media (max-width: 600px) {
  #options {
    top: 60%;
    height: 40%;
    width: 100%;
  }
}
</style>
