<template>
  <div id="home">
    <Options />
    <GridMap />
    <Menu />
  </div><!-- /#home -->
</template>

<script>
import Options from './Options'
import GridMap from './GridMap'
import Menu from './Menu'

export default {
  components: {
    Options,
    GridMap,
    Menu
  },
  created () {
    this.$store.dispatch('initPlugin', this.$route.params.farmId)
  }
}
</script>
