export default {
  setStateProperty (state, options) {
    state[options.property] = options.value
  },
  setAlert (state, value) {
    state.alert = value
    if (value.timeout > 0) {
      setTimeout(function () {
        state.alert.active = false
      }, value.timeout)
    }
  }
}
