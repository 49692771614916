<template>
  <div id="dataset-pisker">
    <label>Choose a Location</label>
    <div class="card p-2">
      <div
        class="mb-2"
      >
        <span
          v-for="(crumb, i) in breadcrumbs"
          v-bind:key="i"
        >
          <b-badge
            pill
            variant="primary"
            @click="launchFolder(crumb, true)"
            class="clickable"
          >{{ crumb.name }}</b-badge><span v-if="(i + 1) !== breadcrumbs.length"> / </span>
        </span>
      </div>
      <!-- FOLDERS -->
      <div class="list-group mb-2 folders" v-if="!loadingFolders">
        <div
          v-for="(folder, i) in $store.state.folders"
          v-bind:key="i"
          class="list-container"
        >
          <a
            @click="launchFolder(folder)"
            class="list-group-item list-group-item-action clickable mb-2 folder"
          >
            <p class="lead mb-1"><i class="fas fa-folder"></i> {{ folder.name }}</p>
            <p class="mb-1">{{ folder.description }}</p>
          </a>
        </div>
      </div><!-- /.list-group -->
      <!-- DATASETS -->
      <div class="list-group sets" v-if="!loadingDatasets">
        <div
        v-for="(dataset, i) in $store.state.datasets"
        v-bind:key="i"
        class="list-container"
        >
          <a class="list-group-item list-group-item-action handle mb-2">
            <p class="lead mb-1"><i v-if="dataset.dynamic" class="fas fa-satellite-dish" v-b-tooltip.hover.top="'Dynamic Dataset'"></i> {{ dataset.name }}</p>
            <p class="mb-1">{{ dataset.description }}</p>
          </a>
        </div>
      </div><!-- /.list-group -->
    </div><!-- /.card -->
  </div><!-- /#dataset-picker -->
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      loadingFolders: true,
      loadingDatasets: true
    }
  },
  computed: {
    breadcrumbs: {
      get () {
        return this.$store.state.breadcrumbs
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'breadcrumbs', value: value })
      }
    }
  },
  created () {
    this.fetchDatasets()
    this.fetchFolders()
  },
  methods: {
    fetchDatasets () {
      this.loadingDatasets = true
      const timeStamp = new Date().getTime()
      const datasetsQuery = 'https://api.efficientvineyard.com/datasets/' + this.$store.state.farm.id + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.folder.id

      axios.get(datasetsQuery).then(response => {
        this.$store.commit('setStateProperty', { property: 'datasets', value: response.data })
        this.loadingDatasets = false
      }).catch(err => {
        console.log(err.message)
      })
    },
    fetchFolders () {
      this.loadingFolders = true
      const timeStamp = new Date().getTime()
      const foldersQuery = 'https://api.efficientvineyard.com/folders/' + this.$store.state.farm.id + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.folder.id

      axios.get(foldersQuery).then(response => {
        this.$store.commit('setStateProperty', { property: 'folders', value: response.data })
        this.loadingFolders = false
      }).catch(err => {
        console.log(err.message)
      })
    },
    launchFolder (folder, fromCrumb) {
      if (this.breadcrumbs.length < 1) {
        this.breadcrumbs = [{ name: 'Root', id: 'root' }]
      }
      if (fromCrumb) {
        const newBreadcrumbs = []
        this.breadcrumbs.some(crumb => {
          if (crumb.id !== folder.id) {
            newBreadcrumbs.push(crumb)
          } else {
            newBreadcrumbs.push(crumb)
            return true
          }
        })
        this.breadcrumbs = newBreadcrumbs
      } else {
        this.breadcrumbs.push(folder)
      }
      this.$store.commit('setStateProperty', { property: 'folder', value: folder })
      this.fetchDatasets()
      this.fetchFolders()
    },
    launchDataset (dataset) {
      this.$store.commit('setStateProperty', { property: 'dataset', value: dataset })
      this.$store.commit('showOptions', true)
    }
  }
}
</script>

<style scoped>
.card > .list-group {
  border-top: none;
  border-bottom: none;
}
.folder {
  background-color: #eee;
}

.badge.clickable:hover {
  box-shadow: 1px 2px 4px rgba(0,0,0,0.5);
}
</style>
