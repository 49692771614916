import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import { store } from './store/store.js'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/app.scss'

Vue.use(BootstrapVue)

Vue.config.productionTip = false

new Vue({ // eslint-disable-line
  el: '#app',
  router,
  store,
  render: h => h(App)
})
