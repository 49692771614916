import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    alert: {
      active: false,
      message: 'This is a basic alert',
      level: 'alert-info'
    },
    avoidEdges: false,
    blocksGeoJson: {},
    breadcrumbs: [
      {
        id: 'root',
        name: 'Root'
      }
    ],
    datasets: [],
    dataset: {},
    datasetGeoJson: {},
    datasetHeader: false,
    distributionModel: 'random',
    folders: [],
    folder: {
      id: 'root',
      name: 'Root'
    },
    download: {},
    farm: {},
    datasetModel: 'nonStratified',
    intervalCount: 10,
    gridCount: 25,
    gridDistance: 3,
    gridDistributedBy: 'distance',
    gridGeoJson: {},
    gridType: 'dataset',
    limit: 10,
    lastVisibleDataset: {},
    loadingMore: false,
    loadingDataset: false,
    loader: false,
    loaderValue: 0,
    loaderMax: 100,
    mapObject: {},
    paginate: false,
    pointsPerZone: 5,
    post: {
      name: 'Grid'
    },
    posting: false,
    sortBy: 'Most Recent',
    selectedBlocks: [],
    spinner: false
  },
  actions: actions,
  mutations: mutations
})
